<template>
  <div>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="9"
        md="8"
      >
        <v-form @submit="create">
          <v-card class="elevation-12">
            <v-toolbar
              color="primary"
              dark
              flat
            >
              <v-toolbar-title>Kandidatúra poslanca NR SR</v-toolbar-title>
              <v-spacer />
            </v-toolbar>
            <v-card-text v-if="user.candidate_approved == false">
              Tvoja žiadosť bola daná <strong>na schválenie</strong>. Po schválení budeš viditeľný v zozname kandidátov.
            </v-card-text>
            <v-card-text v-if="user.candidate_approved == true">
              Tvoja žiadosť bola <strong>schválená</strong>. Si viditeľný v zozname kandidátov.
            </v-card-text>
            <v-card-text v-else-if="user.candidate_id">
              V prípade aktualizácie žiadosti bude Tvoja kandidatúra daná na
              opätovné schválenie administrátorom. </v-card-text>
            <v-card-text v-if="user.verified">
              <v-text-field
                v-model="form.short_info"
                label="Krátke info"
                placeholder="Napíš krátke informácie o sebe, ktoré sa budú zobrazovať pri tvojej kandidatúre"
                name="short_info"
                prepend-icon="mdi-account"
                type="text"
              >

                <template v-slot:prepend>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    Napíš krátke informácie o sebe, ktoré sa budú zobrazovať pri tvojej kandidatúre
                  </v-tooltip>
                </template>
              </v-text-field>

              <v-textarea
                auto-grow
                rows="2"
                v-model="form.long_info"
                label="Dlhé info"
                placeholder="Napíš niečo o sebe, niečo o čom si myslíš, že podporí tvoju kandidatúru a prinesie Ti hlasy"
                name="long_info"
                type="text"
              > <template v-slot:prepend>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    Napíš niečo o sebe, niečo o čom si myslíš, že podporí tvoju kandidatúru a prinesie Ti hlasy
                  </v-tooltip>
                </template>
              </v-textarea>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-file-input
                    v-model="photo_file"
                    accept="image/*"
                    label="Fotografia - sem vlož svoju fotografiu, ktorá sa bude zobrazovať pri tvojom profile kandidáta. Dávaj pozor na výber, lebo aj fotografia naznačuje niečo o osobnosti na fotografii"
                    v-bind="attrs"
                    v-on="on"
                    @mouseenter.native="on.mouseenter"
                    @mouseleave.native="on.mouseleave"
                  ></v-file-input>
                </template>
                <span>
                  Fotografia - sem vlož svoju fotografiu, ktorá sa bude zobrazovať pri tvojom profile kandidáta. Dávaj pozor na výber, lebo aj fotografia naznačuje niečo o osobnosti na fotografii
                </span>
              </v-tooltip>
              <v-img
                v-if="form.photo"
                class="my-5"
                :src="form.photo"
              />

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-file-input
                    v-model="video_file"
                    accept="video/*"
                    label="Video - sem nahraj svoj videosľub"
                    v-bind="attrs"
                    v-on="on"
                    @mouseenter.native="on.mouseenter"
                    @mouseleave.native="on.mouseleave"
                  ></v-file-input>
                </template>
                <span>
                  Video - sem nahraj svoj videosľub
                </span>
              </v-tooltip>

              <div
                v-if="form.video"
                class="text-center"
              >
                <video
                  width="500"
                  controls
                >
                  <source
                    :src="form.video"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>

              <v-checkbox
                v-model="agreement"
                label="Súhlasím s podmienkami"
                name="agreement"
              >
              </v-checkbox>

              <g-d-p-r-consent v-model="agreement_gdpr"></g-d-p-r-consent>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  v-if="!loading"
                  :disabled="!agreement || !agreement_gdpr"
                  color="primary"
                  @click="create"
                >
                  <span> Odoslať žiadosť </span>
                </v-btn>
                <v-progress-circular
                  v-else
                  indeterminate
                  color="success"
                ></v-progress-circular>
              </v-card-actions>
            </v-card-text>
            <v-card-text v-else-if="!user.verified">
              Kandidovať môžeš až po aktivácii svojho používateľského účtu.
            </v-card-text>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar_saved"
      color="success"
      :timeout="10000"
    >
      Tvoja ŽIADOSŤ bola úspešne odoslaná. Po schválení jej obsahu administrátorom bude tvoj pôvodný profil kandidáta nahradený týmto novým profilom po úprave.
      <v-btn
        text
        @click="snackbar_saved = false"
      >
        Zavrieť
      </v-btn>
    </v-snackbar>
    <v-snackbar
      v-model="snackbar_error"
      color="error"
      :timeout="10000"
    >
      Chyba
    </v-snackbar>

  </div>
</template>

<script>
import { APIService } from "@/http/APIService.js";
import { mapGetters } from "vuex";
import GDPRConsent from '../components/GDPRConsent.vue';

const apiService = new APIService();

export default {
  props: {
    source: String,
  },

  components: {
    GDPRConsent
  },

  data() {
    return {
      loading: false,
      drawer: null,
      agreement: false,
      agreement_gdpr: false,

      photo_file: null,
      video_file: null,

      snackbar_saved: false,
      snackbar_error: false,

      user: {},
      form: {
        name: "",
        location: "",
        short_info: "",
        long_info: "",
        photo: null,
        video: null,
      },
    };
  },

  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
  },

  mounted: function () {
    this.getMe();
  },

  methods: {
    getMe() {
      apiService.getMe().then((response) => {
        this.user = response;
        if (this.user.candidate_id != null) {
          apiService.getUserCandidate(this.user.candidate_id).then((response) => {
            this.form = response;
          });
        }
        this.loading = false;
      });
    },

    create() {
      this.loading = true;

      let formData = new FormData();

      for (var key in this.form) {
        if (key == "photo") continue;
        if (key == "video") continue;

        if (this.form[key] != null) formData.set(key, this.form[key]);
      }

      // if (this.photo_file == null) formData.append("photo", "");
      // if (this.video_file == null) formData.append("video", "");

      if (this.photo_file instanceof File)
        formData.append("photo", this.photo_file);

      if (this.video_file instanceof File)
        formData.append("video", this.video_file);

      formData.append("user", this.user.id);

      if (!this.user.candidate_id) {
        if (confirm("Naozaj chcete odoslať žiadosť?")) {
          this.loading = true;
          apiService.createUserCandidate(formData).then((response) => {
            this.form = response;
            this.loading = false;
            this.snackbar_saved = true;
            this.getMe();
          }).finally(() => this.loading = false);
        }
      } else {
        if (confirm("Naozaj chcete odoslať žiadosť?")) {
          this.loading = true;
          apiService.updateUserCandidate(formData, this.form.id).then((response) => {
            this.form = response;
            this.loading = false;
            this.snackbar_saved = true;
            this.getMe();
          }).finally(() => this.loading = false);
        }
      }
    },
  },
};
</script>